.product-img {
    flex: 1;
    padding: 0 30px;
}

.product-desc {
    flex: 1;
}

.product-img-wrapper {
    height: 70vh;
    border-radius: 30px;
    overflow: hidden;
    /* border: rgb(206, 206, 206) 0.5px solid; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.product-img-box{
    transition: 0.5s ease-in-out;
    object-fit: cover;

}

.product-img-box:hover {
    transform: scale(1.1);
    
}

.product-desc>div {
    padding: 30px 20px;
    border-bottom: 0.4px rgb(184, 191, 197) solid;
    color: var(--onyx);
}

.add-to-cart {
    background-color: var(--bittersweet);
    color: #fff;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    gap: 10px;
}

.add-to-wishlist {
    background-color: rgb(199, 199, 199);
    color: #fff;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    gap: 7px;
    margin-right: 10px;
}